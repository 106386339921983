import setupFlickity from '../utils/setupFlickity';

const options = {
  cellAlign: 'left',
  wrapAround: true,
  groupCells: true,
  watchCSS: true,
};

setupFlickity('.js-rulesCarousel', options);
