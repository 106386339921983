import Flickity from 'flickity';
import repeatFnUntilTrue from './repeatFnUntilTrue';

/**
 * setup wrap for Flickity
 *
 * @param {String} wrapper
 * @param {String} module
 * @param {Object} options Flickity Options
 */
export default (module, options, wrapper = '.carousel') => {
  /**
  * Creates new 'Flickity' slider object for '.carousel'
  */
  function flickityInit(wrapperNode) {
    const carouselDiv = wrapperNode.querySelector(wrapper);
    const flickityInstance = new Flickity(carouselDiv, options);

    if (flickityInstance.slides && flickityInstance.slides.length <= 1) {
      carouselDiv.classList.add('carousel--singleGroup');
    }
  }

  /**
  * Checks for are dependencies ready or not
  *
  * If dependencies are ready, initializes slider
  *
  * @returns {Boolean} True, if slider is ready and initialized
  */
  function onSliderReady() {
    const wrapperNodes = document.querySelectorAll(module);
    if (wrapperNodes) {
      wrapperNodes.forEach((wrapperNode) => {
        wrapperNode.classList.remove('is-hidden');
        flickityInit(wrapperNode);
      });
      return true;
    }
    return false;
  }

  repeatFnUntilTrue(onSliderReady);
};
