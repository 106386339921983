/**
 * Calls in some intervals 'callback' until it returns true
 *
 * @param {Function} callback
 * @param {Number} i Internal counter of callback calls
 */
export default function repeatFunctionUntilTrue(callback, i) {
  const index = i || 1;
  if (!callback() && index < 10) {
    // should wait
    const delay = 200 * index;
    setTimeout(() => {
      repeatFunctionUntilTrue(callback, 1 + index);
    }, delay);
  }
}
